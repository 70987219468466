
























































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import Pagination from '@/components/Pagination/index.vue'
import { donwnloadApi } from '@/api/download/'
import { AppModule } from '@/store/modules/app'
interface mySpan {
  row: Object
  column: Object
  rowIndex: number
  columnIndex: number
}
@Component({
  name: 'getorder',
  components: {
    Pagination
  }
})
export default class extends Vue {
  get lang() {
    return AppModule.language.toString()
  }

  private loading = false
  private form = {
    timearr: [],
    task_type: '',
    file_name: '',
    send_from: '',
    send_to: ''
  };

  private columns = [
    { id: 'file_name', label: '任务名称' },
    { id: 'created_at', label: '创建时间' }
  ];

    @Watch('lang', { immediate: true })
  getlang(newone:any) {
    if (newone === 'en') {
      this.columns[0].label = 'Name of task'
      this.columns[1].label = 'Download time'
    } else {
      this.columns[0].label = '任务名称'
      this.columns[1].label = '创建时间'
    }
  }

  private options = [];

  private pagObj = {
    total: 0,
    limit: 20,
    page: 1
  };

  private tdata = []

  private arraySpanMethod = function(obj: mySpan) {
    if (obj.columnIndex === 0) {
      if ([1, 2, 3].includes(obj.rowIndex)) {
        return [0, 0]
      }
      return [4, 1]
    }
  };

  pagination(val: any) {
    console.log(val)
    this.$set(this.pagObj, 'page', val.page)
    this.$set(this.pagObj, 'limit', val.limit)
    this.$set(this.pagObj, 'total', 0)
    this.donwnloadApi()
  }

  created() {
    this.donwnloadApi()
  }

  donwnloadApi() {
    this.loading = true
    const obj = Object.assign({}, { page: this.pagObj.page, page_size: this.pagObj.limit }, this.form)
    if (obj.timearr && obj.timearr.length > 0) {
      obj.send_from = obj.timearr[0]
      obj.send_to = obj.timearr[1]
    }
    const loading = this.$loading({
      lock: true,
      text: 'Loading',
      spinner: 'el-icon-loading'
    })
    donwnloadApi(obj).then(res => {
      this.loading = false
      loading.close()
      if (res.code === '000000') {
        this.pagObj.total = +res.data.total
        this.tdata = res.data.list
      }
    })
  }

  searchRes() {
    this.pagObj.page = 1
    this.donwnloadApi()
  }

  clearall() {
    const dom:any = this.$refs.form
    dom.resetFields()
    this.pagObj.page = 1
    this.donwnloadApi()
  }

  canDownload(status:string, url:string) {
    if (status === '1') {
      window.open(url, '_blank')
    } else {
      this.$message.info('当前文件暂不可下载,稍后重试')
    }
  }
}
