























































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import Pagination from '@/components/Pagination/index.vue'
import { AppModule } from '@/store/modules/app'
import {
  getCountry,
  getShippingLists,
  getPresciptionLists,
  exportdlv,
  exportorders,
  exporttimeline
} from '@/api/prescription'
interface mySpan {
  row: Object
  column: Object
  rowIndex: number
  columnIndex: number
}
@Component({
  name: 'prescription',
  components: {
    Pagination
  }
})
export default class extends Vue {
  get lang() {
    return AppModule.language.toString()
  }

  public form = {
    // month: ['', ''],
    date_start: '',
    date_end: '',
    ship_type: [],
    country_id: []
  };

  private loading = false

  private columns = [
    { label: '运输方式', id: 'ship_type' },
    { label: '目的国家/地区', id: 'cn_name' },
    { label: '日期', id: 'month' },
    { label: '总票数', id: 'count' },
    { label: '妥投票数', id: 'dlv_num' },
    { label: '妥投率', id: 'dlv_rate' },
    { label: '平均时效(自然日)', id: 'time' }
  ];

  @Watch('lang', { immediate: true })
  getlang(newone: any) {
    if (newone === 'en') {
      this.columns[0].label = 'Method of transportation'
      this.columns[1].label = 'Country of destination'
      this.columns[2].label = 'Date'
      this.columns[3].label = 'Total Number'
      this.columns[4].label = 'Delivered Number'
      this.columns[5].label = 'Rate of delivery'
      this.columns[6].label = 'Average time of delivery(natural day)'
      this.columns[1].id = 'en_name'
    } else {
      this.columns[0].label = '运输方式'
      this.columns[1].label = '目的国家/地区'
      this.columns[2].label = '日期'
      this.columns[3].label = '总票数'
      this.columns[4].label = '妥投票数'
      this.columns[5].label = '妥投率'
      this.columns[6].label = '平均时效(自然日)'
      this.columns[1].id = 'cn_name'
    }
  }

  private pagObj = {
    total: 0,
    limit: 20,
    page: 1
  };

  private tdata = [];

  public countryList = [];
  public shippingList = [];

  created() {
    // const val = new Date().getFullYear() + '-' + ((new Date().getMonth() > 9) ? (new Date().getMonth()) : '0' + (new Date().getMonth()).toString())
    const dataTemp = new Date().getTime() - 1000 * 60 * 60 * 24 * 7
    const y = new Date(dataTemp).getFullYear()
    const m = new Date(dataTemp).getMonth() + 1
    const d = new Date(dataTemp).getDate()
    // let m = '0'
    // if (new Date().getMonth() + 1 === 1) {
    //   y = y - 1
    //   m = '12'
    // } else if (new Date().getMonth() + 1 < 10) {
    //   m = '0' + (new Date().getMonth() + 1)
    // }
    const val = y + '-' + m + '-' + d

    this.$set(this.form, 'date_start', val)

    this.getCountry()
    this.getShippingLists()
    this.getPresciptionLists()
  }

  private arraySpanMethod = function(obj: any) {
    if (obj.columnIndex === 0) {
      // 设置合并的列
      if (obj.row.num !== 0) {
        return {
          rowspan: obj.row.num,
          colspan: 1
        }
      } else {
        return {
          rowspan: 0,
          colspan: 0
        }
      }
    }
  };

  disableEnd(date: any) {
    if (this.form.date_start) {
      return date.getTime() <= new Date(this.form.date_start).getTime() - 1000 * 60 * 60 * 24
    }
  }

  disableStart(date: any) {
    if (this.form.date_end) {
      return date.getTime() > new Date(this.form.date_end).getTime()
    }
  }

  public endOptions:any ={
    disabledDate: this.disableEnd
  }

  public startOptions:any ={
    disabledDate: this.disableStart
  }

  pagination(val: any) {
    console.log(val)
    this.$set(this.pagObj, 'page', val.page)
    this.$set(this.pagObj, 'limit', val.limit)
    this.$set(this.pagObj, 'total', 0)
    this.getPresciptionLists()
  }

  public getCountry() {
    const obj = {}
    getCountry(obj).then((res) => {
      this.countryList = res.data
    })
  }

  public getShippingLists() {
    const obj = {}
    getShippingLists(obj).then((res) => {
      this.shippingList = res.data
    })
  }

  public getPresciptionLists() {
    const obj:{
      page: number
      page_size: number
      date_start?: string
      date_end?: string
      ship_type?: string[]
      month?: string[]
      country_id?: string[]
    } = Object.assign(
      {},
      {
        page: this.pagObj.page,
        page_size: this.pagObj.limit
      },
      this.form
    )

    // if (this.form.month[0]) {
    //   obj.date_start = this.form.month[0]
    // }
    // if (this.form.month[1]) {
    //   obj.date_end = this.form.month[1]
    // }
    // if (!this.form.month[0]) {
    //   this.$message.error('请选择日期')
    //   return false
    // } else {
    //   delete obj.month
    // }
    this.loading = true
    getPresciptionLists(obj).then((res) => {
      this.loading = false
      this.pagObj.total = +res.data.total
      const tdata = res.data.list
      const arr:any = []
      this.loading = false
      tdata.forEach((item: any) => {
        let num = 0
        tdata.forEach((per: any) => {
          if (per.ship_type === item.ship_type) {
            num++
          }
        })
        if (arr.indexOf(item.ship_type) > -1) {
          item.num = 0
        } else {
          item.num = num
          arr.push(item.ship_type)
        }
      })
      this.tdata = tdata
    })
  }

  searchRes() {
    this.pagObj.page = 1
    this.getPresciptionLists()
  }

  exportExcle(val: number) {
    const loading = this.$loading({
      lock: true,
      text: 'Loading',
      spinner: 'el-icon-loading'
    })

    const arr = [exporttimeline, exportdlv, exportorders]
    arr[val](this.form).then((res) => {
      loading.close()
      if (res.code === '000000') {
        window.open(res.data.download_url, '_blank')
      } else {
        this.$message.error(res.message)
      }
    }).catch(() => {
      loading.close()
    })
  }

  clearall() {
    const dom: any = this.$refs.form
    dom.resetFields()
    this.$set(this.form, 'date_start', '')
    this.pagObj.page = 1
    this.getPresciptionLists()
  }
}
